.navigation-ancors-wrap {
  min-height: 70px;
}
.navigation-ancors {
  border-top: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  // transition: top $tran;
  height: rem(70px);

  @include phones {
    border: none;
    height: unset;
  }
  
  .container--navigation-ancors {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    @include phones {
      position: fixed;
      top: 0;
      height: 100vh;
      left: 150%;
      transform: translateX(-50%);
      background: #fff;
      width: 100%;
      align-items: flex-start;
      transition: left $tran;
      overflow: hidden;

      &.active {
        left: 50%;
      }
    }
  }

  .hero__btn {
    padding: 10px 15px;

  }
  
  .hero__buy-btn {
    @include phones {
      display: none;
    }
    opacity: 0;
    right: -100%;
    display: flex;
    position: relative;
    font-size: rem(13px);
    transition: right $tran, opacity $tran;
    width: 35%;
    justify-content: flex-end;
    align-items: center;
  }

  .hero__discount-text {
    position: relative;
    margin-right: rem(10px);
    font-size: 10px;
  }

  .hero__discount-arrow {
    position: absolute;
    bottom: -15px;
    right: -20px;
  }

  .navigation-ancors__list {
    justify-content: space-between;
    width: 100%;
    height: rem(70px);
    display: flex;
    align-items: center;
    
    
    @include phones {
      // width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 100px;
      height: unset;
    }
  }

  .navigation-ancors__item {
    margin-right: 0;
  }

  .hero__discount-text {
    position: relative;
    top: 0;
  }

  &.fixed {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    background-color: rgba($white, 0.95);

    .hero__btn {
      opacity: 1;
      right: 0;
      // top: 0;
      // position: absolute;
    }

  }
}

.navigation-ancors__item {
  margin-right: rem(50px);
  display: flex;
  align-items: center;
  height: 100%;
  @include phones {
    height: unset;
    padding: 20px 40px;
  }

  .hero__btn {
    position: relative;
    right: -3000px;
    transition: right $tran, background-color $tran;
  }
}

.navigation-ancors__link {
  font-family: 'Mull-med';
  font-size: rem(15px);

  @include tablets {
    font-size: rem(12px);
  }

  @include phones {
    font-size: 20px;
  }
}