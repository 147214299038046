
$text-color: #272727;
$text-light: #898989;
$white: #FFFFFF;
$bg-light: #E5E5E5;
$bg-dark: #34393f;//#424B53;
$btn-blue: #64A0FF;
$btn-blue-hover: #4888ef;
$btn-green: #61BD29;
$tran: 0.3s;

// resolutions

$phones: 480px;
$tablets: 768px;
$laptop: 992px;
$desktop: 1200px;

//rem function
@function rem ($px) {
  @return $px / 18px + 0rem;
}
//layout
@import './layout/mixin.scss';
@import './layout/fonts.scss';
@import './layout/base.scss';
@import './layout/hamburgers/hamburgers.scss';
@import './blocks/*.scss';