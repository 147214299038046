html, body {
  height: 100%;
  width: 100%;
  font-size: 18px;
  scroll-behavior: smooth;
}

body {
  font-family: 'Mull-reg', Arial, sans-serif;
  -webkit-font-snoothing: antialiased;
  -moz-osx-font-snoothing: grayscale;
  color: #000;
  line-height: 1.42;
  font-size: rem(18px);
}

h1, h2, h3, h4, h5, h6, p {
  font-family: 'Mull-reg', Arial, sans-serif;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

p {
  margin: 5px 0;
  text-align: justify;
}

button {
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  width: 100%;
}

section {
  padding-top: 70px !important;
  h2 {
    text-align: center;
  }
}

.wrapper {
  overflow: hidden;
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.maincontent {
  height: 100%;
  transition: transform 1s;
}

.container {
  max-width: rem(1200px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;

  @include phones {
    width: 90%;
  }
}

.title {
  text-align: center;
  font-size: rem(30px);
}

.subtitle {
  font-family: 'Mull-bold', Arial;
  font-weight: 600;
  font-size: rem(17px);
}

.text {
  font-size: rem(15px);
  color: $text-color;
}

.terms {
  p {
    a {
      color: $btn-blue;
    }
  }
}

.m-0 {
  margin: 0;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-50 {
  margin-top: 50px;
}
.text-primary {
  color: #4888ef;
}
ul.marked {
  margin-left: 15px;
  li {
    list-style: disc;
  }
}
.panel {
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 3px 1px #cccccc;
}


.underlined::after {
  content: "";
  display: block;
  background: #64a0ff;
  width: 100px;
  height: 3px;
  position: absolute;
}

.btn {
  border-radius: 6px;
  color: white;
  font-size: 15px;
  font-family: "Mull-med";
  padding: 1.11111rem 1.94444rem;
  transition: background-color 0.3s;
}
.btn-primary {
  background-color: $btn-blue;
  box-shadow: 0px 4px 4px rgba(100, 160, 255, 0.25);
  &:hover {
    background-color: $btn-blue-hover;
    cursor: pointer;
  }
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}