@font-face {
  font-family: "Mull-med";
  src: url("../fonts/MullerMedium.eot") format("eot"), url("../fonts/MullerMedium.woff") format("woff"), url("../fonts/MullerMedium.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Mull-reg";
  src: url("../fonts/MullerRegular.eot") format("eot"), url("../fonts/MullerRegular.woff") format("woff"), url("../fonts/MullerRegular.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Mull-bold";
  src: url("../fonts/MullerBold.eot") format("eot"), url("../fonts/MullerBold.woff") format("woff"), url("../fonts/MullerBold.ttf") format("truetype");
  font-display: swap; }

html, body {
  height: 100%;
  width: 100%;
  font-size: 18px;
  scroll-behavior: smooth; }

body {
  font-family: 'Mull-reg', Arial, sans-serif;
  -webkit-font-snoothing: antialiased;
  -moz-osx-font-snoothing: grayscale;
  color: #000;
  line-height: 1.42;
  font-size: 1rem; }

h1, h2, h3, h4, h5, h6, p {
  font-family: 'Mull-reg', Arial, sans-serif; }

ul {
  margin: 0;
  padding: 0; }

ul li {
  list-style: none; }

p {
  margin: 5px 0;
  text-align: justify; }

button {
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: #000; }

img {
  width: 100%; }

section {
  padding-top: 70px !important; }
  section h2 {
    text-align: center; }

.wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative; }

.maincontent {
  height: 100%;
  transition: transform 1s; }

.container {
  max-width: 66.66667rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%; }
  @media screen and (max-width: 480px) {
    .container {
      width: 90%; } }

.title {
  text-align: center;
  font-size: 1.66667rem; }

.subtitle {
  font-family: 'Mull-bold', Arial;
  font-weight: 600;
  font-size: 0.94444rem; }

.text {
  font-size: 0.83333rem;
  color: #272727; }

.terms p a {
  color: #64A0FF; }

.m-0 {
  margin: 0; }

.mb-50 {
  margin-bottom: 50px; }

.mt-50 {
  margin-top: 50px; }

.text-primary {
  color: #4888ef; }

ul.marked {
  margin-left: 15px; }
  ul.marked li {
    list-style: disc; }

.panel {
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 3px 1px #cccccc; }

.underlined::after {
  content: "";
  display: block;
  background: #64a0ff;
  width: 100px;
  height: 3px;
  position: absolute; }

.btn {
  border-radius: 6px;
  color: white;
  font-size: 15px;
  font-family: "Mull-med";
  padding: 1.11111rem 1.94444rem;
  transition: background-color 0.3s; }

.btn-primary {
  background-color: #64A0FF;
  box-shadow: 0px 4px 4px rgba(100, 160, 255, 0.25); }
  .btn-primary:hover {
    background-color: #4888ef;
    cursor: pointer; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #FFFFFF; }

.hamburger-box {
  width: 33px;
  height: 18px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 33px;
    height: 2px;
    background-color: #FFFFFF;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.not-found {
  padding-top: 70px; }

.not-found__content {
  display: flex;
  flex-direction: column;
  align-items: center; }

.not-found__title {
  margin-top: 20px; }

.not-found__text {
  text-align: center; }

.not-found__link {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  text-align: center;
  color: #64A0FF; }

.footer {
  margin-top: 3.88889rem;
  font-size: 0.77778rem;
  line-height: 26px;
  background-color: #34393f;
  color: #E5E5E5; }

.footer__logo {
  width: 7.22222rem;
  margin-top: 25px; }

.footer__main {
  margin-top: 2.22222rem;
  display: flex;
  align-items: flex-start;
  width: 100%; }
  @media screen and (max-width: 480px) {
    .footer__main {
      flex-direction: column; } }

.footer__icon-pic {
  width: 126px;
  height: 39px; }

.footer__main-text {
  width: 100%;
  margin-left: 4.44444rem; }
  @media screen and (max-width: 768px) {
    .footer__main-text {
      margin-left: 1.11111rem; } }
  @media screen and (max-width: 480px) {
    .footer__main-text {
      margin-left: 0; } }

.footer__nav {
  width: 100%; }

.footer__nav-list {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .footer__nav-list {
      flex-direction: column; } }
  .footer__nav-list .footer__nav-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px; }
    .footer__nav-list .footer__nav-item h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px; }
    .footer__nav-list .footer__nav-item a {
      color: #E5E5E5;
      display: flex;
      margin: 5px 0; }
      .footer__nav-list .footer__nav-item a svg {
        height: 25px;
        width: 25px;
        margin-right: 10px; }

.footer__copy {
  margin-top: 1.11111rem; }

.hamburger {
  display: none;
  position: absolute;
  right: 0;
  outline: none; }
  @media screen and (max-width: 480px) {
    .hamburger {
      display: inline-block; } }
  .hamburger.is-active {
    position: relative;
    z-index: 11;
    position: absolute;
    right: 0; }

.container--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 0 0; }
  @media screen and (max-width: 480px) {
    .container--header {
      padding: 0;
      height: 70px;
      align-items: center; } }

.header {
  background-color: #34393f; }
  @media screen and (max-width: 480px) {
    .header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 12; } }

@media screen and (max-width: 480px) {
  .header__logo.disabled {
    display: none; } }

.header__logo-icon {
  width: 100%; }

.header__logo-pic {
  height: 38px;
  width: 126px; }

.header__content {
  display: flex;
  align-items: center;
  position: relative; }
  @media screen and (max-width: 480px) {
    .header__content {
      width: 100%; } }

.header__dashboard-btn {
  font-size: 0.77778rem; }
  @media screen and (max-width: 480px) {
    .header__dashboard-btn {
      display: none !important; } }

.header__btn-link {
  color: #FFFFFF;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  margin-left: 20px; }
  .header__btn-link svg {
    width: 20px;
    height: 25px;
    margin-right: 10px; }

.header__dashboard-text {
  font-family: 'Mull-med';
  font-size: 0.83333rem;
  color: #272727;
  margin-left: 15px; }

.header__lang-dropdown-sel-flag {
  display: flex;
  align-items: center; }

.header__lang-drop-flag-icon {
  width: 34px;
  height: 34px; }

.header__lang {
  margin-left: 70px;
  font-family: 'Mull-med';
  font-size: 14px; }
  .header__lang .header__lang-dropdown-select {
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
    color: #272727; }

.header__lang-drop-icon {
  width: 14px;
  height: 7px; }

.header__lang-dropdown-menu {
  display: none; }

.cars tbody tr td {
  border: 1px solid #00a5ce; }
  .cars tbody tr td img {
    width: 100%;
    padding: 10px; }

#first-screen {
  background: linear-gradient(126.8deg, #ffffff 17.86%, rgba(255, 255, 255, 0) 77.61%), #f8f8f8; }

.hero {
  padding: 1.66667rem 0; }
  @media screen and (max-width: 480px) {
    .hero {
      background: #fff;
      padding: 5rem 0; } }

.hero__wrap {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  @media screen and (max-width: 480px) {
    .hero__wrap {
      flex-direction: column-reverse; } }

.hero__text-content {
  width: 45%; }
  @media screen and (max-width: 480px) {
    .hero__text-content {
      width: 100%; } }

.hero__title {
  font-size: 1.77778rem;
  overflow-wrap: break-word; }
  @media screen and (max-width: 480px) {
    .hero__title {
      text-align: center; } }

.hero__usage-description {
  margin-top: 16px;
  font-size: 0.77778rem;
  color: #898989; }
  @media screen and (max-width: 480px) {
    .hero__usage-description {
      text-align: center; } }

.hero__description {
  margin-top: 37px;
  font-size: 0.94444rem; }
  @media screen and (max-width: 480px) {
    .hero__description {
      text-align: center; } }

.hero__btns {
  margin-top: 40px;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 480px) {
    .hero__btns {
      flex-direction: column; } }

.hero__btn {
  box-shadow: 0px 4px 4px rgba(100, 160, 255, 0.25);
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 15px;
  font-family: "Mull-med";
  padding: 1.11111rem 1.94444rem;
  transition: background-color 0.3s; }
  @media screen and (max-width: 768px) {
    .hero__btn {
      padding: 0.83333rem 0.83333rem; } }
  @media screen and (max-width: 480px) {
    .hero__btn {
      padding: 1.11111rem 1.94444rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; } }
  .hero__btn:hover {
    cursor: pointer;
    background-color: #4888ef; }

.hero__buy-btn {
  position: relative; }
  @media screen and (max-width: 480px) {
    .hero__buy-btn {
      margin-top: 10px; } }

.hero__btn--download {
  background-color: #64A0FF; }

.hero__btn--buy {
  background-color: #61BD29;
  display: flex;
  align-items: center; }

.hero__buy-button-icon {
  width: 15px; }
  .hero__buy-button-icon img {
    width: 100%; }

.hero__buy-button-text {
  margin-left: 5px; }

.hero__discount-text {
  position: absolute;
  font-size: 15px;
  bottom: -130%; }
  @media screen and (max-width: 480px) {
    .hero__discount-text {
      left: 50%;
      transform: translateX(-50%); } }
  .hero__discount-text span {
    font-family: "Mull-med";
    font-weight: 900;
    text-transform: uppercase; }

.hero__discount-arrow {
  position: absolute;
  bottom: 7px;
  right: -30px; }

.hero__visual-content {
  width: 50%;
  align-self: center; }
  @media screen and (max-width: 480px) {
    .hero__visual-content {
      width: 100%; } }

@media screen and (max-width: 480px) {
  .hero__image {
    width: 100%; }
    .hero__image img {
      width: 100%; } }

.hero__platforms-list {
  display: flex;
  margin-top: 3.88889rem;
  width: 100%;
  justify-content: space-around; }
  @media screen and (max-width: 480px) {
    .hero__platforms-list {
      display: none; } }

.hero__platforms-list-duble {
  display: none; }
  @media screen and (max-width: 480px) {
    .hero__platforms-list-duble {
      display: flex;
      justify-content: space-around;
      margin-top: 3.88889rem;
      width: 100%; } }

.hero__platforms-item {
  display: flex;
  font-size: 0.83333rem; }
  @media screen and (max-width: 480px) {
    .hero__platforms-item {
      flex-direction: column;
      align-items: center;
      justify-content: center; } }

.hero__platforms-icon {
  height: 40px;
  width: 40px; }
  .hero__platforms-icon.hero__platforms-icon--web {
    width: 70px; }

.hero__platforms-pic {
  width: 100%;
  height: 100%; }

.hero__platforms-text {
  margin-left: 8px;
  width: 45%; }
  @media screen and (max-width: 480px) {
    .hero__platforms-text {
      margin-left: 0;
      margin-top: 20px;
      text-align: center; } }
  .hero__platforms-text a {
    color: #64A0FF; }

.menu {
  background-color: #34393f;
  padding: 22px 0; }
  @media screen and (max-width: 480px) {
    .menu {
      padding: 0; } }

.menu__list {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 480px) {
    .menu__list {
      position: fixed;
      top: 0;
      height: 100vh;
      left: 150%;
      transform: translateX(-50%);
      background: #fff;
      width: 100%;
      align-items: flex-start;
      transition: left 0.3s;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 25% 10px; }
      .menu__list.active {
        left: 50%; } }

@media screen and (max-width: 480px) {
  .menu__item {
    margin-bottom: 20px; } }

.menu__item-link {
  font-family: 'Mull-med';
  font-size: 15px;
  color: #FFFFFF; }
  @media screen and (max-width: 480px) {
    .menu__item-link {
      color: #272727;
      font-size: 14px; } }

label.pointer:hover {
  cursor: pointer; }

.modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.9);
  transition: opacity .25s ease; }

.modal__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer; }

.modal-state {
  display: none; }

.modal-state:checked + .modal {
  opacity: 1;
  visibility: visible; }

.modal-state:checked + .modal .modal__inner {
  top: 0; }

.modal__inner {
  transition: top .25s ease;
  position: absolute;
  top: -20%;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 550px;
  width: 50%;
  margin: auto;
  overflow: auto;
  background: #fff;
  border-radius: 5px;
  padding: 1em 2em;
  height: max-content; }

.modal__close {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer; }

.modal__close:after,
.modal__close:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 1.5em;
  background: #ccc;
  display: block;
  transform: rotate(45deg);
  left: 50%;
  margin: -3px 0 0 -1px;
  top: 0; }

.modal__close:hover:after,
.modal__close:hover:before {
  background: #aaa; }

.modal__close:before {
  transform: rotate(-45deg); }

@media screen and (max-width: 768px) {
  .modal__inner {
    width: 90%;
    height: 90%;
    box-sizing: border-box; } }

.navigation-ancors-wrap {
  min-height: 70px; }

.navigation-ancors {
  border-top: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  height: 3.88889rem; }
  @media screen and (max-width: 480px) {
    .navigation-ancors {
      border: none;
      height: unset; } }
  .navigation-ancors .container--navigation-ancors {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    @media screen and (max-width: 480px) {
      .navigation-ancors .container--navigation-ancors {
        position: fixed;
        top: 0;
        height: 100vh;
        left: 150%;
        transform: translateX(-50%);
        background: #fff;
        width: 100%;
        align-items: flex-start;
        transition: left 0.3s;
        overflow: hidden; }
        .navigation-ancors .container--navigation-ancors.active {
          left: 50%; } }
  .navigation-ancors .hero__btn {
    padding: 10px 15px; }
  .navigation-ancors .hero__buy-btn {
    opacity: 0;
    right: -100%;
    display: flex;
    position: relative;
    font-size: 0.72222rem;
    transition: right 0.3s, opacity 0.3s;
    width: 35%;
    justify-content: flex-end;
    align-items: center; }
    @media screen and (max-width: 480px) {
      .navigation-ancors .hero__buy-btn {
        display: none; } }
  .navigation-ancors .hero__discount-text {
    position: relative;
    margin-right: 0.55556rem;
    font-size: 10px; }
  .navigation-ancors .hero__discount-arrow {
    position: absolute;
    bottom: -15px;
    right: -20px; }
  .navigation-ancors .navigation-ancors__list {
    justify-content: space-between;
    width: 100%;
    height: 3.88889rem;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 480px) {
      .navigation-ancors .navigation-ancors__list {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 100px;
        height: unset; } }
  .navigation-ancors .navigation-ancors__item {
    margin-right: 0; }
  .navigation-ancors .hero__discount-text {
    position: relative;
    top: 0; }
  .navigation-ancors.fixed {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95); }
    .navigation-ancors.fixed .hero__btn {
      opacity: 1;
      right: 0; }

.navigation-ancors__item {
  margin-right: 2.77778rem;
  display: flex;
  align-items: center;
  height: 100%; }
  @media screen and (max-width: 480px) {
    .navigation-ancors__item {
      height: unset;
      padding: 20px 40px; } }
  .navigation-ancors__item .hero__btn {
    position: relative;
    right: -3000px;
    transition: right 0.3s, background-color 0.3s; }

.navigation-ancors__link {
  font-family: 'Mull-med';
  font-size: 0.83333rem; }
  @media screen and (max-width: 768px) {
    .navigation-ancors__link {
      font-size: 0.66667rem; } }
  @media screen and (max-width: 480px) {
    .navigation-ancors__link {
      font-size: 20px; } }

.products {
  margin-top: 20px;
  display: flex; }
  .products .container h2 {
    font-weight: 500;
    font-size: 1.66667rem;
    margin: 0; }
  .products .container p.subtitle {
    margin-bottom: 0; }
  .products .container .products img {
    width: min-content;
    height: min-content; }
  .products .container .products .product-item {
    padding: 5px;
    font-size: 15px;
    text-align: justify; }
    .products .container .products .product-item .card {
      margin: 5px;
      padding: 20px 10px;
      border-radius: 6px;
      border: 1px solid #ddd;
      box-shadow: 0 0 1px 1px #eee;
      text-align: center; }
      .products .container .products .product-item .card h3 {
        font-weight: bold;
        text-align: center;
        font-size: 18px;
        margin-bottom: 5px;
        margin-top: 0px; }
      .products .container .products .product-item .card ul {
        margin: 10px 20px 20px 40px;
        text-align: justify; }
        .products .container .products .product-item .card ul li {
          list-style: disc; }
      .products .container .products .product-item .card label {
        padding: 15px 10px;
        margin: auto;
        margin-top: 20px; }
        @media screen and (max-width: 480px) {
          .products .container .products .product-item .card label {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center; } }

p.price {
  text-align: center; }

span.price-value {
  font-size: 18px;
  color: red;
  font-weight: bold; }

ul.benefits {
  display: flex;
  flex-direction: row; }
  @media screen and (max-width: 768px) {
    ul.benefits {
      flex-direction: column; } }
  ul.benefits li {
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center; }
    @media screen and (max-width: 768px) {
      ul.benefits li {
        width: 100%; } }
    ul.benefits li img {
      max-width: 100px;
      max-height: 100px; }
    ul.benefits li span {
      text-align: center;
      margin-top: 20px; }

.text-with-icon {
  display: flex; }
  .text-with-icon img {
    height: 32px;
    margin-right: 10px; }

@media screen and (max-width: 768px) {
  .text-with-icon {
    justify-content: center;
    text-align: center; }
    .text-with-icon img {
      display: none; } }

.dop-section h5 {
  margin-top: 0;
  display: flex;
  align-items: center; }
