.menu {
  
  background-color: $bg-dark;
  padding: 22px 0;

  @include phones {
    // display: none;
    padding: 0;
  }
}

.menu__list {
  display: flex;
  justify-content: space-between;

  @include phones {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 150%;
    transform: translateX(-50%);
    background: #fff;
    width: 100%;
    align-items: flex-start;
    transition: left $tran;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 25% 10px;

    &.active {
      left: 50%;
    }
  }
}

.menu__item {
  @include phones {
    margin-bottom: 20px;
  }
}

.menu__item-link {
  font-family: 'Mull-med';
  font-size: 15px;
  color: $white;
  @include phones {
    color: $text-color;
    font-size: 14px;
  }
}
label.pointer:hover {
  cursor: pointer;
}