.hamburger {
  display: none;
  position: absolute;
  right: 0;
  outline: none;
  
  
  
  @include phones {
    display: inline-block;
  }
  
  &.is-active {
    position: relative;
    z-index: 11;
    position: absolute;
    right: 0;
  }
}