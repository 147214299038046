.footer {
  margin-top: rem(70px);
  font-size: rem(14px);
  line-height: 26px;
  background-color: $bg-dark;
  color: $bg-light;
}

.footer__logo {
  width: rem(130px);
  margin-top: 25px;
}

.footer__main {
  margin-top: rem(40px);
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @include phones {
    flex-direction: column;
  }
}

.footer__icon-pic {
  width: 126px;
  height: 39px;
}

.footer__main-text {
  width: 100%;
  margin-left: rem(80px);

  @include tablets {
    margin-left: rem(20px);
  }

  @include phones {
    margin-left: 0;
  }
}

.footer__nav {
  width: 100%;
}

.footer__nav-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @include tablets {
    flex-direction: column;
  }
  .footer__nav-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
    }
    a {
      color: $bg-light;
      display: flex;
      margin: 5px 0;
      svg {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
}



.footer__copy {
  margin-top: rem(20px);
}