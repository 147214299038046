.not-found {
  padding-top:70px;
}

.not-found__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found__title {
  margin-top: 20px;
}

.not-found__text {
  text-align: center;
}

.not-found__link {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  text-align: center;
  color: $btn-blue;
}