.products {
  margin-top: 20px;
  display: flex;
  .container {
    h2 {
      font-weight: 500;
      font-size: rem(30px);
      margin: 0;
    }
    p.subtitle {
      margin-bottom: 0;
    }
    .products {
      //display: flex;
      //flex-direction: row;
      //justify-content: space-between;
      img {
        width: min-content;
        height: min-content;
      }
      .product-item {
        padding: 5px;
        font-size: 15px;
        text-align: justify;
        //max-width: 20%;
        .card {
          margin: 5px;
          padding: 20px 10px;
          border-radius: 6px;
          border: 1px solid #ddd;
          box-shadow: 0 0 1px 1px #eee;
          text-align: center;
          h3 {
            font-weight: bold;
            text-align: center;
            font-size: 18px;
            margin-bottom: 5px;
            margin-top: 0px;
          }
          ul {
            margin: 10px 20px 20px 40px;
            text-align: justify;
            li {
              list-style: disc;
            }
          }
          label {
            padding: 15px 10px;
            margin: auto;
            margin-top: 20px;
            @include phones {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

p.price {
  text-align: center;
}
span.price-value{
  font-size: 18px;
  color: red;
  font-weight: bold;
}

ul.benefits {
  display: flex;
  flex-direction: row;
  @include tablets {
    flex-direction: column;
  }
  li {
    display: flex;
    flex-direction: column;
    width: 20%;
    @include tablets {
      width: 100%;
    }
    align-items: center;
    img {
      max-width: 100px;
      max-height: 100px;
    }
    span {
      text-align: center;
      margin-top: 20px;
    }
  }
}



.text-with-icon {
  display: flex;
  img {
    height: 32px;
    margin-right: 10px;
  }
}

@include tablets {
  .text-with-icon {
    justify-content: center;
    text-align: center;
    img {
      display: none;
    }
  }
}

.dop-section {
  h5 {
    margin-top: 0;
    display: flex;
    align-items: center;
  }
}