#first-screen {
  background: linear-gradient(
      126.8deg,
      #ffffff 17.86%,
      rgba(255, 255, 255, 0) 77.61%
    ),
    #f8f8f8;
}
.hero {
  padding: rem(30px) 0;
  @include phones {
    background: #fff;
    padding: rem(90px) 0;
  }
}

.hero__wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include phones {
    flex-direction: column-reverse;
  }
}

.hero__text-content {
  width: 45%;

  @include phones {
    width: 100%;
  }
}

.hero__title {
  font-size: rem(32px);
  overflow-wrap: break-word;

  @include phones {
    text-align: center;
  }
}

.hero__usage-description {
  margin-top: 16px;
  font-size: rem(14px);
  color: $text-light;

  @include phones {
    text-align: center;
  }
}

.hero__description {
  margin-top: 37px;
  font-size: rem(17px);

  @include phones {
    text-align: center;
  }
}

.hero__btns {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  @include phones {
    flex-direction: column;
  }
}

.hero__btn {
  box-shadow: 0px 4px 4px rgba(100, 160, 255, 0.25);
  border-radius: 6px;
  color: $white;
  font-size: 15px;
  font-family: "Mull-med";
  padding: rem(20px) rem(35px);
  transition: background-color $tran;

  @include tablets {
    padding: rem(15px) rem(15px);
  }

  @include phones {
    padding: rem(20px) rem(35px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
    background-color: $btn-blue-hover;
  }
}

.hero__buy-btn {
  position: relative;

  @include phones {
    margin-top: 10px;
  }
}

.hero__btn--download {
  background-color: $btn-blue;
}

.hero__btn--buy {
  background-color: $btn-green;
  display: flex;
  align-items: center;
}

.hero__buy-button-icon {
  width: 15px;

  & img {
    width: 100%;
  }
}

.hero__buy-button-text {
  margin-left: 5px;
}

.hero__discount-text {
  position: absolute;
  font-size: 15px;
  bottom: -130%;

  @include phones {
    left: 50%;
    transform: translateX(-50%);
  }

  & span {
    font-family: "Mull-med";
    font-weight: 900;
    text-transform: uppercase;
  }
}

.hero__discount-arrow {
  position: absolute;
  bottom: 7px;
  right: -30px;
}

.hero__visual-content {
  width: 50%;
  align-self: center;

  @include phones {
    width: 100%;
  }
}

.hero__image {
  @include phones {
    width: 100%;

    & img {
      width: 100%;
    }
  }
}

.hero__platforms-list {
  display: flex;
  margin-top: rem(70px);
  width: 100%;
  justify-content: space-around;

  @include phones {
    display: none;
  }
}

.hero__platforms-list-duble {
  display: none;

  @include phones {
    display: flex;
    justify-content: space-around;
    margin-top: rem(70px);
    width: 100%;
    // justify-content: space-around;
  }
}

.hero__platforms-item {
  display: flex;
  font-size: rem(15px);
  // width: 40%;

  @include phones {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.hero__platforms-icon {
  height: 40px;
  width: 40px;

  &.hero__platforms-icon--web {
    width: 70px;
  }
}

.hero__platforms-pic {
  width: 100%;
  height: 100%;
}

.hero__platforms-text {
  margin-left: 8px;
  width: 45%;
  @include phones {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }

  & a {
    color: $btn-blue;
  }
}
