.container--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 0 0;
  @include phones {

    padding: 0;
    height: 70px;
    align-items: center;
  }
}

.header {
  @include phones {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    //background: rgba($white, 0.95);
  }
  background-color: $bg-dark;
}

.header__logo {
  //height: rem(50px);
  @include phones {
    &.disabled {
      display: none;
    }
  }
}

.header__logo-icon {
  width: 100%;
}

.header__logo-pic {
  height: 38px;
  width: 126px;
}

.header__content {
  display: flex;
  align-items: center;  
  position: relative;
  @include phones {
    width: 100%;
  }
}

.header__dashboard-btn {
  font-size: rem(14px);
  @include phones {
    display: none !important;
    //position: relative;
    //left: 150%;
    //transition: left 0.3s;
    //&.active {
    //  display: block;
    //z-index: 13;
    //left: 0;
    //}
  }
}

.header__btn-link {
  color: $white;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  margin-left: 20px;
  svg {
    width: 20px;
    height: 25px;
    margin-right: 10px;
  }
}

.header__dashboard-text {
  font-family: 'Mull-med';
  font-size: rem(15px);
  color: $text-color;
  margin-left: 15px;

}

.header__lang-dropdown-sel-flag {
  display: flex;
  align-items: center;
}

.header__lang-drop-flag-icon {
  width: 34px;
  height: 34px;
}

.header__lang {
  margin-left: 70px;
  font-family: 'Mull-med';
  font-size: 14px;
  
  .header__lang-dropdown-select {
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
    color: $text-color;
    
  }
}

.header__lang-drop-icon {
  width: 14px;
  height: 7px;
}
.header__lang-dropdown-menu {
  display: none;
}



.cars {
  tbody {
    tr {
      td {
        border: 1px solid #00a5ce;
        img {
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
}