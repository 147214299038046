@font-face {
  font-family: "Mull-med";
  src: url("../fonts/MullerMedium.eot") format("eot"),
    url("../fonts/MullerMedium.woff") format("woff"),
    url("../fonts/MullerMedium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Mull-reg";
  src: url("../fonts/MullerRegular.eot") format("eot"),
    url("../fonts/MullerRegular.woff") format("woff"),
    url("../fonts/MullerRegular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Mull-bold";
  src: url("../fonts/MullerBold.eot") format("eot"),
    url("../fonts/MullerBold.woff") format("woff"),
    url("../fonts/MullerBold.ttf") format("truetype");
  font-display: swap;
}
